exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dP95 ._32v8i{margin-top:-15px;-webkit-box-sizing:border-box;box-sizing:border-box;height:33px;display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}._3dP95 ._32v8i ._25DFc{background:#c4424f;width:50%}._3dP95 ._32v8i ._1hsqa{width:950px;display:-ms-flexbox;display:flex;-ms-flex-align:start;align-items:flex-start;-ms-flex-pack:justify;justify-content:space-between}._3dP95 ._32v8i ._1hsqa::before{content:' ';width:0;height:0;border-bottom:33px solid #c4424f;border-right:45px solid transparent}._3dP95 ._32v8i ._1hsqa::after{content:' ';width:0;height:0;border-bottom:33px solid #c4424f;border-left:45px solid transparent}._3dP95 ._32v8i .X-Q-t{background:#c4424f;width:50%}._3dP95 ._26pKB{padding:20px 15px;text-align:center;background:#c4424f}._3dP95 ._26pKB ._2bFCd{max-width:1180px}.tTplm{color:rgba(255,255,255,0.5)}._3n7Lv{color:rgba(255,255,255,0.3)}.tTplm,.NoJN3{padding:2px 5px;font-size:1em}.NoJN3,.NoJN3:active,.NoJN3:visited{color:rgba(255,255,255,0.6);text-decoration:none}.NoJN3:hover{color:#fff}\n", ""]);

// exports
exports.locals = {
	"root": "_3dP95",
	"tooth": "_32v8i",
	"left": "_25DFc",
	"center": "_1hsqa",
	"right": "X-Q-t",
	"container": "_26pKB",
	"content": "_2bFCd",
	"text": "tTplm",
	"spacer": "_3n7Lv",
	"link": "NoJN3"
};