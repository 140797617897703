exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Gxun ._12ku7{margin-left:22px;text-decoration:none;font-size:18px;font-weight:400}._2Gxun ._12ku7,._2Gxun ._12ku7:active,._2Gxun ._12ku7:visited{color:#d0d3da}._2Gxun ._12ku7:hover{color:#fff}\n", ""]);

// exports
exports.locals = {
	"root": "_2Gxun",
	"link": "_12ku7"
};