exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._14IZ-{background:#5d626e}.izfMl{margin:0 auto;max-width:1180px;height:134px;color:#d0d3da;font-size:18px;text-transform:uppercase;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}\n", ""]);

// exports
exports.locals = {
	"root": "_14IZ-",
	"container": "izfMl"
};