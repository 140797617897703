import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Header.scss';
import Link from '../Link';
import Navigation from '../Navigation';
import logoUrl from './logo.png';

class Header extends React.Component {
  render() {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <Link to="/">
            <img src={logoUrl} alt="Frezu.com" />
          </Link>
          <Navigation />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Header);
