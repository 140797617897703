import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Navigation.scss';
import Link from '../Link';

class Navigation extends React.Component {
  render() {
    return (
      <div className={s.root} role="navigation">
        <Link className={s.link} to="/asd-to-asd">
          Services
        </Link>
        <Link className={s.link} to="/contact">
          Api
        </Link>
        <Link className={s.link} to="/login">
          Case studies
        </Link>
        <Link className={s.link} to="/register">
          Business resources
        </Link>
        <Link className={s.link} to="/register">
          Pricing and languages
        </Link>
      </div>
    );
  }
}

export default withStyles(s)(Navigation);
